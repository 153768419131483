import { createApp } from "vue/dist/vue.esm-bundler";

export const initVueApp = (selector, Component) => {
  const block = document.querySelector(selector);
  if (block) {
    const app = createApp(Component);
    app.config.compilerOptions.delimiters = ["[[", "]]"];
    app.mount(selector);
  }
};
